import React from 'react'
import { graphql } from 'gatsby'

import Layout from 'layouts/index'
import Seo from 'components/seo'
import Cover from 'components/common/cover'
import WideCard from 'components/common/wideCard'
import UseCase from 'components/common/useCase'
import TabCover from 'components/common/tabCover'
import SimpleSlider from 'components/carousel/simple'
import ContentCarousel from 'components/common/contentCarousel'
import overviewCard from '/static/images/platform/overview_bar.webp'
import overview from '/static/images/platform/overview_hero.webp'
import { overviewTabs, ManagementFeatures } from '/static/data/platform/overview.static'
import { allCdnList } from '/static/data/cdnBrand.static'

const BRAND_SLIDER_SETTINGS = {
  infinite: true,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: true,
  pauseOnHover: false,
  speed: 9000,
  autoplaySpeed: 1000,
  cssEase: 'linear'
}

const Overview = () => {
  return (
    <Layout>
      <React.Fragment>
        <Seo
          seo={{
            metaTitle: `Mlytics Platform: Powering Global Application Delivery`,
            metaDescription: `Mlytics brings together the world’s top networks for a seamless, cost-efficient application delivery experience. Enhance performance, reliability, and scalability.`,
            metaUrl: 'www.mlytics.com/platform/overview',
            shareImage: {
              imageUrl: 'https://www.mlytics.com/wp-content/uploads/2023/03/og_v5.jpg',
              imageWidth: 1200,
              imageHeight: 630,
              imageType: 'image/jpeg'
            }
          }}
        />
        <section
          style={{
            height: 'calc(100vh - 100px)',
            minHeight: '600px'
          }}
          className="px-9 md:px-[86px] mb-5 lg:max-h-[800px] base:absolute base:left-1/2 base:-translate-x-1/2 base:w-screen base:max-w-[1920px]"
        >
          <Cover
            smallTitle="Overview"
            title="You Built. We Deliver."
            imgSrc={overview}
            imgAlt="Platform Overview Hero Picture"
            paragraphContent="Mlytics fully internalizes the best elements of the world’s best networks, including Akamai, Cloudflare, Google Cloud, Tencent, Ali cloud, AWS and many more, so you can enjoy all the benefits without unnecessary management and cost."
            actionLinkOne="/contact-us"
            actionTextOne="Start Your Journey"
          />
          <div className="hidden md:block absolute bottom-[-100px] left-0 w-full h-[140px] base:bottom-[-180px]">
            <div className="hidden px-[86px] py-5 md:block">
              <SimpleSlider className="slick-bg-white-gradient-x" settings={BRAND_SLIDER_SETTINGS}>
                {allCdnList.map((item) => (
                  <div key={item.alt}>
                    <img src={item.src} alt={item.alt} height="80" width="160" />
                  </div>
                ))}
              </SimpleSlider>
            </div>
          </div>
        </section>
        <div className="hidden mb-5 lg:max-h-[800px] base:block base:h-[calc(100vh-100px)] base:min-h-[600px]"></div>
        <section className="pt-[100px] md:pt-[200px] pb-[60px] px-9 md:px-[86px]">
          <div className="w-full flex flex-col gap-10">
            <WideCard
              title="We deliver 50+ PB every month/n/We manage 10,000+ domains and 500,000+ digital assets"
              imgSrc={overviewCard}
              imgAlt="Platform delivers 50+ PB per month"
              paragraphTitle=""
              paragraphContent="Volume equals experiences. Mlytics platform has helped 500+ customers deliver and manage their critical applications to the best possible state at global scale."
              actionLink="https://www.mlytics.com/blog/category/customer-stories/"
              actionText="Learn More"
            />
          </div>
        </section>
        <section className="py-[60px] px-9 md:px-[86px]">
          <div className="w-full flex flex-col gap-10">
            <h2 className="h2-block-title text-black-100 text-center">Built for Easy Deployment and Management</h2>
            <ContentCarousel listItem={ManagementFeatures} />
          </div>
        </section>
        <section className="py-[60px] px-9 md:px-[86px] flex justify-center">
          <TabCover title="Leverage Our Capabilities for Your Business" tabItems={overviewTabs} />
        </section>
        <section className="py-[60px]">
          <UseCase />
        </section>
      </React.Fragment>
    </Layout>
  )
}

export default Overview

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
