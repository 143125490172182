import gaming from '/static/images/platform/overview_scenario_gaming.webp'
import iot from '/static/images/platform/overview_scenario_iot_device_manufacturer.webp'
import media from '/static/images/platform/overview_scenario_media.webp'
import publisher from '/static/images/platform/overview_scenario_publisher.webp'
import one from '/static/images/platform/overview_feature_1.webp'
import two from '/static/images/platform/overview_feature_2.webp'
import three from '/static/images/platform/overview_feature_3.webp'
import four from '/static/images/platform/overview_feature_4.webp'

export const overviewTabs = [
  {
    id: 'gaming',
    content: 'Gaming',
    picture: gaming,
    coverTitle: 'Highest performance',
    coverContent: '',
    link: '/use-case/gaming',
    cta: 'Learn More'
  },
  {
    id: 'iot',
    content: 'IoT device manufacturer',
    picture: iot,
    coverTitle: 'Handle peak with ease',
    coverContent: '',
    link: '/use-case/iot-device-manufacturer',
    cta: 'Learn More'
  },
  {
    id: 'media',
    content: 'Media',
    picture: media,
    coverTitle: 'Superior viewing experiences',
    coverContent: '',
    link: '/use-case/media',
    cta: 'Learn More'
  },
  {
    id: 'publisher',
    content: 'Publisher',
    picture: publisher,
    coverTitle: 'AI generative content',
    coverContent: '',
    link: '/use-case/publisher',
    cta: 'Learn More'
  }
]

export const ManagementFeatures = [
  {
    title: 'Hassle-free activation',
    content: 'Integrating with your application in just a few clicks. ',
    imageSrc: one,
    imageAlt: 'Hassle-free activation'
  },
  {
    title: 'Fastest deployment',
    content: 'Deploy your applications to hundreds of PoP of your choice within minutes.',
    imageSrc: two,
    imageAlt: 'Fastest deployment'
  },
  {
    title: 'DNS-level optimization',
    content:
      'Mlytics platform allows you to optimize user experience at DNS level via analyzing or load balancing, which is closest to end users.',
    imageSrc: three,
    imageAlt: 'DNS-level optimization'
  },
  {
    title: 'Zero downtime',
    content:
      'Mlytics platform ensures the highest application availability via routing and switching among all the networks, not one.',
    imageSrc: four,
    imageAlt: 'Zero downtime'
  }
]
